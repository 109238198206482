import EditDate from "../components/EditDate"
import InteractiveMap from "../components/InteractiveMap"
import { getLastUpdated } from "../functions/utils"
import FreeText from "./FreeText"

const AirportSimple = ({ data }) => {
    const date = getLastUpdated(data)
    const { airportName, airportCode, locations, additionalInfo } = data.fields
    return (
        <>
           <div className="flex-between-centered" style={{marginBottom: 20}}>
                <b className="content_h4">{airportName} {airportCode && `(${airportCode})`}</b>
                <EditDate date={date} />
            </div>
            {locations && <InteractiveMap lng={locations.lon} lat={locations.lat} />}
            {additionalInfo && <FreeText data={additionalInfo} slug='additionalInfo' />}
        </>
    )
}

export default AirportSimple