import mapboxgl from 'mapbox-gl';
import { useEffect, useRef, useState } from 'react';

mapboxgl.accessToken = process.env.MAPBOX_ACCESS_TOKEN;

const InteractiveMap = ({ lng, lat }) => {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [isVisible, setIsVisible] = useState(false)
    const [wasVisible, setWasVisible] = useState(false)
    const [textAddress, setTextAddress] = useState('')

    async function getAddressFromCoordinates(lng, lat) {
        const res = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${mapboxgl.accessToken}`
        )
        const data = await res.json()
        if (data.features.length) {
            setTextAddress(data.features[0].place_name)
        }
    }

    useEffect(() => {
        if (!textAddress) getAddressFromCoordinates(lng, lat)
    }, [textAddress])
    

    useEffect(() => {
        const onScroll = () => {
            const rect = mapContainer.current?.getBoundingClientRect()
            const isInview = rect && (rect.top >= 0) && (rect.bottom <= window.innerHeight)
            if (!wasVisible) {
                setIsVisible(isInview)
                if (isInview) setWasVisible(true)
            }
        }
        window.addEventListener("scroll", onScroll)
    
        return () => window.removeEventListener("scroll", onScroll)
      }, [])


    
    useEffect(() => {
        if (!textAddress) return
        if (!isVisible) return
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [lng, lat],
            zoom: 14
        });
        const marker = new mapboxgl.Marker({color: '#006CB5'}).setLngLat([lng, lat]).addTo(map.current)
        const popup = new mapboxgl.Popup({ offset: 35 }).setText(textAddress).addTo(map.current)
        marker.setPopup(popup)
    })
 
    return (
        <div ref={mapContainer} style={{height: 300}} />
     )
}

export default InteractiveMap