


import { deCamelize, getLastUpdated } from "../functions/utils"
import EditDate from "../components/EditDate"
import DefaultAsset from "../components/DefaultAsset"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

const RichTextRenderer = ({ richTextDocument }) => {
    const renderOptions = {
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
          const { url, title, description } = node.data.target.fields.file;
          return (
            <div className="embedded-asset">
              <img src={url} alt={description || title} />
              {description && <p>{description}</p>}
            </div>
          );
        },
      },
    };
  
    return <>{documentToReactComponents(richTextDocument, renderOptions)}</>;
};

const FreeText = ({ data, slug, title }) => {
    const date = getLastUpdated(null, data)
    if (!data) return null
    return (
        <div id={slug}>
            <div className="flex-between-centered">
                <h3 className="content_h3">{deCamelize(title || slug)}</h3>
                <EditDate date={date} />
            </div>
            {data.map((text, i) => {
                if (!text || !text.fields) return
                const { internalName, additionalNotes, image } = text.fields
                return (
                    <div className="block-style" key={i}>
                        {internalName && <h3 style={{marginTop: 10, marginBottom: 30}} className="content_h3">{internalName}</h3>}
                        {additionalNotes && <RichTextRenderer richTextDocument={additionalNotes} />}
                        {image && <DefaultAsset data={[image]} fullWidth /> }
                    </div>
                )
            })}
        </div>
    )
}

export default FreeText
