import DefaultAsset from "../components/DefaultAsset"
import EditDate from "../components/EditDate"
import { deCamelize, getLastUpdated } from "../functions/utils"
import FreeText from "./FreeText"
import HotelSimple from "./HotelSimple"
import Location from "./Location"

const Hotel = ({ data, slug }) => {
    const date = getLastUpdated(null, data)
    return (
        <article id={slug}>
            <div className="flex-between-centered">
                <h2 className="content_h2">{deCamelize(slug)}</h2>
                <EditDate date={date} />
            </div>
            {data.map((el, i) => {
                if (!el || !el.fields) return
                const { name, hotelType, hotelLocation, hotelImages, hotelRoomFeatures, link, phone, shuttleOffered, shuttleArrangement, additionalInfo } = el.fields
               return (
                    <div key={i}>
                        {name && <b className="content_h3">{name}</b>}
                        {hotelType && (
                            <div style={{marginTop: 20}}>
                                <b>Hotel Type</b>
                                <ul className="list-style" style={{marginTop: 10}}>
                                    {hotelType.map(v => <li key={v}>{v}</li>)}
                                </ul>
                            </div>
                        )}
                        {hotelLocation && hotelLocation.map((loc, i)=> {
                            const type = loc.sys.contentType.sys.id
                            return (
                                <div className="block-style" style={{marginTop: 20}} key={i}>
                                    {type === 'hotelSimple' && <HotelSimple data={loc} />}
                                    {type === 'location' && <Location data={loc} />}
                                </div>
                            )
                        })}
                        {hotelImages && <DefaultAsset data={hotelImages} />}
                        {hotelRoomFeatures && (
                            <div style={{marginTop: 20}}>
                                <b>Hotel Room Features</b>
                                <ul className="list-style" style={{marginTop: 10}}>
                                    {hotelRoomFeatures.map(v => <li key={v}>{v}</li>)}
                                </ul>
                            </div>
                        )}
                        {link && (
                            <div className='d-flex-align-center' style={{marginTop: 20}}>
                                <b>Website Link:</b>
                                <a className='link-wrap' href={link} target='_blank'>{link}</a>
                            </div>
                        )}
                        {phone && <p><b>Phone:</b> <a href={`tel:${phone}`}>{phone}</a></p>}
                        {shuttleOffered !== undefined && (
                            <p>
                                <b>Does the Hotel Have Its Own Shuttle?</b>
                                {shuttleOffered ? ' Yes' : ' No'}
                            </p>
                        ) }
                        {shuttleArrangement && (
                            <div>
                                <b>How Does a Guest Arrange the Shuttle?</b>
                                <p>{shuttleArrangement}</p>
                            </div>
                        )}
                        {additionalInfo && <FreeText data={additionalInfo} slug='additionalInfo' />}
                    </div>
                )
            })}
        </article>
    )
}

export default Hotel