import { deCamelize, getLastUpdated } from "../functions/utils"
import EditDate from "../components/EditDate"
import AirportDetailed from "./AirportDetailed"
import HotelSimple from "./HotelSimple"
import DockInformation from "./DockInformation"
import Location from "./Location"
import AirportSimple from "./AirportSimple"
import Hotel from "./Hotel"

const MeetingPoints = ({ data, slug }) => {
    const date = getLastUpdated(null, data)
    return (
        <article id={slug}>
            <div className="flex-between-centered">
                <h3 className="content_h3">{deCamelize(slug)}</h3>
                <EditDate date={date} />
            </div>
            {data.map((point, i) => {
                if (!point || !point.fields) return
                const type = point.sys.contentType.sys.id
                return (
                    <div className="block-style" key={i}>
                        {type === 'hotel' && <Hotel data={[point]} slug="hotels" />}
                        {type === 'testAirportSimple' && <AirportSimple data={point} />}
                        {type === 'airport' && <AirportDetailed data={point} />}
                        {type === 'hotelSimple' && <HotelSimple data={point} />}
                        {type === 'dockInformation' && <DockInformation data={point} />}
                        {type === 'location' && <Location data={point} />}
                    </div>
                )
            })}
        </article>
    )
}

export default MeetingPoints
